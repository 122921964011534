import React from 'react'
import { ActionButton, Avatar, Item, VStack, Widget } from '@revolut/ui-kit'
import { useLapeContext, useLapeField } from '@src/features/Form/LapeForm'
import {
  GrowthPlanActionInterface,
  GrowthPlanInterface,
} from '@src/interfaces/growthPlans'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { ActionItem } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/ActionItems/ActionItem'

const actionsFieldName = 'actions'

export const ActionItemsWidget = () => {
  const { values } = useLapeContext<GrowthPlanInterface>()
  const actionsField = useLapeField(actionsFieldName)

  const makeChangeHandler = (index: number) => (value: string) => {
    values.actions[index].description = value
  }

  const makeDeleteHandler = (index: number) => () => {
    values.actions.splice(index, 1)
  }

  const handleAdd = () => {
    values.actions.push({ description: '' } as GrowthPlanActionInterface)
  }

  const canAddActionItem =
    values.actions.length === 0 ||
    values.actions.every(({ description }) => !!description)

  return (
    <Widget>
      <Item style={transparentThemeBackgroundOverrides}>
        <Item.Avatar>
          <Avatar useIcon="Questionnaire" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Growth plan actions</Item.Title>
          <Item.Description>
            To-do items for employee to successfully complete growth plan
          </Item.Description>
        </Item.Content>
      </Item>
      <VStack p="s-16" space="s-16" data-name="actions">
        {values.actions.map((action, index) => (
          <ActionItem
            key={action.id}
            action={action}
            hasError={actionsField.error && !action.description}
            errorMessage={
              actionsField.error && !action.description ? 'Cannot be empty' : undefined
            }
            onChange={makeChangeHandler(index)}
            onDelete={makeDeleteHandler(index)}
            dataName={`${actionsFieldName}.${index}.description`}
          />
        ))}
        <ActionButton useIcon="Plus" disabled={!canAddActionItem} onClick={handleAdd}>
          Add to-do
        </ActionButton>
      </VStack>
    </Widget>
  )
}
