import { ActionButton, ActionButtonProps, Button, StatusPopup } from '@revolut/ui-kit'
import React, { useCallback, useEffect, MouseEvent } from 'react'
import {
  useCreateMergeConnection,
  useActivateMergeConnection,
  useGetMergeConnections,
  useDeleteMergeConnection,
} from '@src/api/integrations'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { MergeIntegration, MergeConnectionInterface } from '@src/interfaces/integrations'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

const MERGE_API_URL = 'https://api-eu.merge.dev'

interface ConnectMergeIntegrationButtonProps extends ActionButtonProps {
  integration: MergeIntegration
  onSuccess?: () => void
  onFailure?: () => void
  askConsent?: boolean
}

const integrationNameMap: Record<MergeIntegration, string> = {
  bamboohr: 'BambooHR',
  hibob: 'HiBob',
}

export const ConnectMergeIntegrationButton = ({
  integration,
  onSuccess,
  onFailure,
  onClick,
  askConsent,
  ...rest
}: ConnectMergeIntegrationButtonProps) => {
  const { data: integrations, isLoading } = useGetMergeConnections()
  const showStatusPopup = useShowStatusPopup()
  const {
    mutateAsync: createConnection,
    data,
    isLoading: isCreatingConnection,
    isSuccess,
  } = useCreateMergeConnection()
  const { mutateAsync: activateConnection } = useActivateMergeConnection(data?.data.id)
  const otherIntegration = integrations?.results?.find(
    int => int.integration_type !== integration,
  )
  const currentIntegration = integrations?.results?.find(
    int => int.integration_type === integration,
  )
  const { mutateAsync: deleteConnection } = useDeleteMergeConnection()

  const onConnectSuccess = useCallback(
    async public_token => {
      try {
        await activateConnection([undefined, { public_token }])
        onSuccess?.()
      } catch (err) {
        onFailure?.()
        showStatusPopup({
          status: 'error',
          title: 'Failed to activate connection',
          description: getStringMessageFromError(
            err,
            'Something went wrong. Please try again.',
          ),
        })
      }
    },
    [onSuccess, showStatusPopup],
  )

  const { open, isReady } = useMergeLink({
    linkToken: data?.data.token,
    onSuccess: onConnectSuccess,
    tenantConfig: {
      apiBaseURL: MERGE_API_URL,
    },
  })

  useEffect(() => {
    if (isSuccess && isReady) {
      open()
    }
  }, [isSuccess, isReady])

  const connectIntegration = async () => {
    try {
      await createConnection({ integration_type: integration })
    } catch (err) {
      onFailure?.()
      showStatusPopup({
        status: 'error',
        title: 'Failed to enable',
        description: getStringMessageFromError(
          err,
          'Something went wrong. Please try again.',
        ),
      })
    }
  }

  const disconnectIntegration = async (connection: MergeConnectionInterface) => {
    try {
      showStatusPopup({
        title: `Disabling existing ${
          integrationNameMap[connection.integration_type]
        } integration`,
        status: 'pending',
      })
      await deleteConnection(connection.id)
    } catch (error) {
      showStatusPopup({
        title: 'Cannot disable integration',
        description: getStringMessageFromError(error),
        status: 'error',
      })
    }
  }

  const switchIntegration = async () => {
    if (otherIntegration) {
      await disconnectIntegration(otherIntegration)
    }
    showStatusPopup({
      title: `Enabling ${integrationNameMap[integration]} integration`,
      status: 'pending',
    })
    await connectIntegration()
  }

  const showChangeIntegrationPopup = async () => {
    if (!otherIntegration) {
      await switchIntegration()
      return
    }

    showStatusPopup({
      status: 'warning',
      title: 'Change employee source',
      description: (
        <>
          This action will disable your existing integration with{' '}
          {integrationNameMap[otherIntegration.integration_type]}.
          <br />
          <br />
          Are you sure you want to continue?
        </>
      ),
      actions: statusPopup => (
        <StatusPopup.Actions>
          <Button variant="secondary" onClick={() => statusPopup.hide()}>
            Cancel
          </Button>
          <Button elevated onClick={switchIntegration}>
            Continue
          </Button>
        </StatusPopup.Actions>
      ),
    })
  }

  const showDisconnectIntegrationPopup = async () => {
    if (!currentIntegration) {
      return
    }

    showStatusPopup({
      status: 'warning',
      title: 'Are you sure?',
      description: `After disabling this integration you won’t be able to use ${integrationNameMap[integration]} sync`,
      actions: statusPopup => (
        <StatusPopup.Actions>
          <Button variant="secondary" onClick={() => statusPopup.hide()}>
            Cancel
          </Button>
          <Button elevated onClick={() => disconnectIntegration(currentIntegration)}>
            Continue
          </Button>
        </StatusPopup.Actions>
      ),
    })
  }

  const showConsentPopup = async () => {
    if (!askConsent) {
      otherIntegration ? showChangeIntegrationPopup() : connectIntegration()
      return
    }

    showStatusPopup({
      status: 'warning',
      title: `Connect to ${integrationNameMap[integration]}`,
      description: `By clicking Continue, you agree that Merge API, Inc. will access personal data from ${integrationNameMap[integration]} as our sub-processor`,
      actions: statusPopup => (
        <StatusPopup.Actions>
          <Button variant="secondary" onClick={() => statusPopup.hide()}>
            Cancel
          </Button>
          <Button
            elevated
            onClick={() => {
              statusPopup.hide()
              otherIntegration ? showChangeIntegrationPopup() : connectIntegration()
            }}
          >
            Continue
          </Button>
        </StatusPopup.Actions>
      ),
    })
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onClick?.(e)
    showConsentPopup()
  }

  if (
    currentIntegration?.last_sync?.state === 'success' ||
    currentIntegration?.last_sync?.state === 'failure'
  ) {
    return (
      <ActionButton onClick={showDisconnectIntegrationPopup} variant="negative">
        Disconnect
      </ActionButton>
    )
  }

  if (currentIntegration?.last_sync) {
    const lastSync = currentIntegration?.last_sync
    return (
      <ActionButton
        onClick={() => {
          navigateTo(
            pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION, {
              id: lastSync.id,
            }),
          )
        }}
      >
        View
      </ActionButton>
    )
  }

  return (
    <ActionButton
      pending={isCreatingConnection}
      disabled={isCreatingConnection || isLoading}
      onClick={handleClick}
      {...rest}
    />
  )
}
