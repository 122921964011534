import React from 'react'
import { Flex, HStack, Icon, Text, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import { gradeSettings } from '@src/pages/Forms/DepartmentForm/Performance/utils'
import { GradeAndPercent } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import { formatPercentage } from '@src/utils/format'

interface ProgressStatProp extends GradeAndPercent {
  statName: string
  tooltipText?: JSX.Element
}

export const ProgressStat = ({
  grade,
  percent,
  tooltipText,
  statName,
}: ProgressStatProp) => {
  const tooltip = useTooltip()

  return (
    <Flex
      minWidth={110}
      mx="s-16"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <HStack space="s-4" align="center">
        <Text
          variant="heading3"
          whiteSpace="nowrap"
          color={gradeSettings?.[grade]?.color}
        >
          {formatPercentage(percent)}
        </Text>
        {tooltipText && (
          <>
            <Icon
              name="InfoOutline"
              size={16}
              color={Token.color.greyTone50}
              style={{ cursor: 'pointer' }}
              {...tooltip.getAnchorProps()}
            />
            <Tooltip {...tooltip.getTargetProps()} width={240} p="s-12">
              {tooltipText}
            </Tooltip>
          </>
        )}
      </HStack>
      <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="caption">
        {statName}
      </Text>
    </Flex>
  )
}
