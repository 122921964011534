import React from 'react'
import { abbreviate, Avatar, Box, DualAvatar, Status } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { HeaderActions } from './HeaderActions'
import { formatRRule } from '@src/utils/format'
import { getAvatarUrl } from '@src/utils/employees'
import { ROUTES } from '@src/constants/routes'
import { OneToOneMeeting } from '@src/interfaces/meetings'

interface Props {
  data?: OneToOneMeeting
  isLoading: boolean
  onChangeSchedule: VoidFunction
}

export const SummaryHeader = ({ data, isLoading, onChangeSchedule }: Props) => {
  if (!data && !isLoading) {
    return null
  }

  const title = `1:1 ${data?.manager.name} <> ${data?.employee.name}`
  const status = formatRRule(data?.meeting_series?.recurrence)

  return (
    <PageHeader
      isLoading={isLoading}
      title={
        <PageHeader.Title
          avatar={
            <Box p="s-2">
              <DualAvatar
                topImage={
                  data?.manager.avatar ? (
                    getAvatarUrl(data.manager.avatar)
                  ) : (
                    <Avatar
                      uuid={data?.manager.full_name}
                      label={
                        data?.manager.full_name ? abbreviate(data.manager.full_name) : ''
                      }
                    />
                  )
                }
                bottomImage={
                  data?.employee.avatar ? (
                    getAvatarUrl(data.employee.avatar)
                  ) : (
                    <Avatar
                      uuid={data?.employee.full_name}
                      label={
                        data?.employee.full_name
                          ? abbreviate(data.employee.full_name)
                          : ''
                      }
                    />
                  )
                }
                size={56}
              />
            </Box>
          }
          labels={<Status useIcon="Calendar">{status}</Status>}
          title={title}
          actions={<HeaderActions onChangeSchedule={onChangeSchedule} />}
        />
      }
      backUrl={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.OVERVIEW}
    />
  )
}
