import {
  differenceInDays,
  formatDistanceToNow,
  isFuture,
  isPast,
  isSameDay,
  isTomorrow,
  isYesterday,
} from 'date-fns'
import { Token, Text, Color, IconName } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import {
  FinalGrade,
  ReviewCategory,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

export const gradeSettings: Record<
  FinalGrade,
  { title: string; color: Color; icon: IconName | undefined }
> = {
  [FinalGrade.Empty]: {
    title: 'Pending',
    color: Token.color.foreground,
    icon: undefined,
  },
  [FinalGrade.None]: {
    title: 'Pending',
    color: Token.color.foreground,
    icon: undefined,
  },
  [FinalGrade.Poor]: {
    title: 'Unsatisfactory',
    color: Token.color.warning,
    icon: 'ExclamationMark',
  },
  [FinalGrade.AverageMinus]: {
    title: 'Developing',
    color: Token.color.foreground,
    icon: 'TurboTransfer',
  },
  [FinalGrade.AveragePlus]: {
    title: 'Performing',
    color: Token.color.success,
    icon: 'SocialLike',
  },
  [FinalGrade.Strong]: {
    title: 'Exceeding',
    color: Token.color.success,
    icon: 'Trophy',
  },
  [FinalGrade.Exceptional]: {
    title: 'Exceptional',
    color: Token.color.purple,
    icon: 'Cleaning',
  },
} as const

export const getTimelineStepState = (
  startDate: string,
  endDate: string,
  category?: string,
) => {
  if (category === 'meeting') {
    return 'pending'
  }

  if (isFuture(new Date(startDate))) {
    return 'default'
  }

  if (isPast(new Date(endDate))) {
    return 'done'
  }

  return 'pending'
}

export const getDateDistance = (date: string) => {
  if (!date) {
    return <>-</>
  }

  const dayDiff = differenceInDays(new Date(date), new Date())
  return (
    <Text color={dayDiff <= 3 ? Token.color.orange : Token.color.greyTone50}>
      {`in ${formatDistanceToNow(new Date(date))}`}
    </Text>
  )
}

export const getCategory = (category: ReviewCycleCategory | undefined) => {
  switch (category) {
    case ReviewCycleCategory.Probation:
      return ReviewCategory.Probation
    case ReviewCycleCategory.PIP:
      return ReviewCategory.PIP_V2
    default:
      return ReviewCategory.Performance
  }
}

export const getPeriodCategoryBadgeContent = (
  periodCategory: ReviewCategory | ReviewCycleCategory | undefined,
  periodName: string | undefined,
) => {
  switch (periodCategory) {
    case ReviewCategory.PIP:
    case ReviewCategory.PIP_V2:
    case ReviewCycleCategory.PIP:
    case ReviewCycleCategory.PIP_V2:
      return 'PIP'
    case ReviewCategory.Probation:
    case ReviewCycleCategory.Probation:
      return 'P'
    default:
      return periodName?.split(' ')[0]
  }
}

export const getTimelineStepDescriptionWithDistance = (
  event: SingleTimelineEventInterface,
  isManualCycle?: boolean,
) => {
  if (isManualCycle) {
    return null
  }

  if (event.category === 'meeting') {
    let dayReference
    if (isSameDay(new Date(), new Date(event.end_period))) {
      dayReference = 'today'
    } else if (isYesterday(new Date(event.end_period))) {
      dayReference = 'yesterday'
    } else if (isTomorrow(new Date(event.end_period))) {
      dayReference = 'tomorrow'
    }

    return (
      <>
        <Text use="p">
          {`${formatDate(event.start_period, 'EEE, d MMM yyyy')}`}
          {dayReference && <Text color={Token.color.orange}>{` - ${dayReference}`}</Text>}
        </Text>
      </>
    )
  }

  if (event.category === 'review_results') {
    if (isPast(new Date(event.start_period))) {
      return (
        <Text>{`Closed ${formatDate(
          event.start_period,
          'd MMMM yyyy',
        )} - ${formatDistanceToNow(new Date(event.start_period), {
          addSuffix: true,
        })}`}</Text>
      )
    }
    return (
      <>
        <Text use="p">
          {`Closes ${formatDate(event.start_period, 'd MMMM yyyy')} - `}
          {getDateDistance(event.start_period)}
        </Text>
      </>
    )
  }

  if (isPast(new Date(event.end_period))) {
    return (
      <Text>{`Closed ${formatDate(
        event.end_period,
        'd MMMM yyyy',
      )} - ${formatDistanceToNow(new Date(event.end_period), {
        addSuffix: true,
      })}`}</Text>
    )
  }

  if (isFuture(new Date(event.start_period))) {
    return (
      <Text>
        {`Opens ${formatDate(event.start_period, 'd MMMM yyyy')} - `}
        {getDateDistance(event.start_period)}
      </Text>
    )
  }

  if (
    event.category === 'kpi' ||
    event.category === 'review' ||
    event.category === 'promotion' ||
    event.category === 'calibration'
  ) {
    return (
      <>
        <Text use="p">
          {`Closes ${formatDate(event.end_period, 'd MMMM yyyy')} - `}
          {getDateDistance(event.end_period)}
        </Text>
      </>
    )
  }

  return <>-</>
}

export const getTimelineStepDescription = (
  event: SingleTimelineEventInterface,
  isManualCycle?: boolean,
) => {
  if (isManualCycle) {
    return null
  }

  if (event.category === 'meeting') {
    return (
      <>
        <Text use="p">{formatDate(event.start_period, 'dd MMM')}</Text>
      </>
    )
  }

  if (event.category === 'review_results' || event.category === 'calibration') {
    const closedPeriodDate =
      event.category === 'review_results' ? event.start_period : event.end_period

    if (isPast(new Date(event.start_period)) && isFuture(new Date(event.end_period))) {
      return <Text>{`Closes ${formatDate(event.end_period, 'dd MMM')}`}</Text>
    }
    if (isPast(new Date(closedPeriodDate))) {
      return <Text>{`Closed ${formatDate(closedPeriodDate, 'dd MMM')} `}</Text>
    }
  }

  if (isFuture(new Date(event.start_period))) {
    return <Text>{`Opens ${formatDate(event.start_period, 'dd MMM')} `}</Text>
  }

  if (isPast(new Date(event.end_period))) {
    return <Text>{`Closed ${formatDate(event.end_period, 'dd MMM')} `}</Text>
  }

  if (
    event.category === 'kpi' ||
    event.category === 'review' ||
    event.category === 'promotion'
  ) {
    return (
      <>
        <Text use="p">{`Closes ${formatDate(event.end_period, 'dd MMM')}`}</Text>
      </>
    )
  }

  return <>-</>
}

export const canViewProgressionComponent = (data: EmployeeInterface) => {
  const senioritySublevel = data.specialisation_seniority_sublevel?.sublevel
  const maxSublevelToSeeProgression = 2
  return senioritySublevel && senioritySublevel <= maxSublevelToSeeProgression
}
