import { AxiosPromise, AxiosResponse } from 'axios'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { API } from '@src/constants/api'
import { api, apiWithoutHandling } from '@src/api/index'
import { ExportTypes } from '@src/constants/export'
import {
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import {
  ConnectionInterface,
  ConnectionTestInterface,
  ReportInterface,
  RunReportResponseInterface,
  RunQueryResponseInterface,
  RunQueryRequestInterface,
  TenantQueryDeploymentInterface,
  QueryInterface,
  QueryRunResultInterface,
} from '@src/interfaces/dataAnalytics'
import { CatalogInterface } from '@src/pages/Forms/QueryForm/components/DatabaseSchema'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetch, useUpdate } from '@src/utils/reactQuery'
import { OptionInterface } from '@src/interfaces/selectors'

export const queriesTableRequests: TableRequestInterface<QueryInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.REPORTING}/queries`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
}

export const useQueriesOptions = (filtersParams: FilterByInterface[]) => {
  const result = useFetch<GetRequestInterface<QueryInterface>>(
    `${API.REPORTING}/queries`,
    'v1',
    {
      params: {
        page_size: 100000,
        ...filterSortPageIntoQuery(undefined, filtersParams),
      },
    },
  )

  return result.data?.results
}

export const queryRequestsNew: RequestInterfaceNew<QueryInterface> = {
  get: async ({ id }) => api.get(`${API.REPORTING}/queries/${id}`),
  update: async (_, { id }, data) => {
    return api.put(`${API.REPORTING}/queries/${id}`, data)
  },
  submit: async data => {
    return api.post(`${API.REPORTING}/queries`, data)
  },
  delete: async ({ id }) => api.delete(`${API.REPORTING}/queries/${id}`),
}

export const connectionsTableRequests: TableRequestInterface<ConnectionInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `/data/connections`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
}

export const connectionRequestsNew: RequestInterfaceNew<ConnectionInterface> = {
  get: async ({ id }) => api.get(`/data/connections/${id}`),
  update: async (diff, { id }, data, initialData) => {
    const oldValues = omit(initialData, ['name', 'description', 'owner', 'status'])
    const newValues = omit(data, ['name', 'description', 'owner', 'status'])
    const updateData = isEqual(newValues, oldValues)
      ? { ...diff }
      : { ...diff, ...newValues }
    return api.patch(`/data/connections/${id}`, updateData)
  },
  submit: async data => api.post(`/data/connections`, data),
  delete: async ({ id }) => api.delete(`/data/connections/${id}`),
}

export const reportsTableRequests: TableRequestInterface<ReportInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.REPORTING}/reports`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
}

export const reportRequestsNew: RequestInterfaceNew<ReportInterface> = {
  get: async ({ id }) => api.get(`${API.REPORTING}/reports/${id}`),
  update: async (_, { id }, data) => {
    const updateData = { ...data }

    // these fields could not be set to null from UI in order to clean up previous values
    // but API expects nulls
    if (!data?.scheduling_type) {
      updateData.schedule = null
    }
    if (data?.scheduling_type?.id !== 'basic') {
      updateData.run_every = null
      updateData.run_on_day_of_month = null
      updateData.run_on_day_of_week = null
      updateData.run_on_month = null
      updateData.run_on_time = null
      updateData.run_on_timezone = null
    }

    return api.put(`${API.REPORTING}/reports/${id}`, updateData)
  },
  submit: async data => {
    const submitData = { ...data }

    // these fields could not be deleted from UI in order to clean up previous values
    // but API does not expect these fields
    if (!data.scheduling_type) {
      delete submitData.schedule
    }
    if (data.scheduling_type?.id !== 'basic') {
      delete submitData.run_every
      delete submitData.run_on_day_of_month
      delete submitData.run_on_day_of_week
      delete submitData.run_on_month
      delete submitData.run_on_time
      delete submitData.run_on_timezone
    }

    return api.post(`${API.REPORTING}/reports`, submitData)
  },
  delete: async ({ id }) => api.delete(`${API.REPORTING}/reports/${id}`),
}

export const useGetReport = (id: number | string) =>
  useFetch<ReportInterface>(`${API.REPORTING}/reports/${id}`)

export const archiveQuery = (id: number, action: 'archive' | 'unArchive') =>
  api.put<QueryInterface>(`${API.REPORTING}/queries/${id}/${action}`)

export const useUpdateQuery = () =>
  useUpdate<QueryInterface, QueryInterface>(
    `${API.REPORTING}/queries`,
    undefined,
    undefined,
    false,
    (_oldData, newData) => newData,
    true,
  )

export const postTestConnection = (data: ConnectionInterface) =>
  apiWithoutHandling.post(`/data/connections/test`, data)

export const getTestConnection = (id: number) =>
  apiWithoutHandling.get<ConnectionTestInterface>(`/data/connections/${id}/test`)

export const avtivateQuery = (queryId: number) =>
  apiWithoutHandling.put(`${API.REPORTING}/queries/${queryId}/activate`)

export const adhocQueryRun = (
  data: RunQueryRequestInterface,
): AxiosPromise<RunQueryResponseInterface> =>
  apiWithoutHandling.post(`${API.REPORTING}/adhocQueryRuns`, data)

export const queryRun = (
  queryId: number,
  data: RunQueryRequestInterface,
  dashboardId?: number | string,
): AxiosPromise<RunQueryResponseInterface> => {
  const postData = { ...data }
  delete postData.query.sql

  return apiWithoutHandling.post(
    dashboardId
      ? `${API.DASHBOARDS}/internal/${dashboardId}/queries/${queryId}/runs`
      : `${API.REPORTING}/queries/${queryId}/runs`,
    data,
  )
}

export const getQueryRun = async (queryId: number, runId: number) => {
  return apiWithoutHandling.get<RunQueryResponseInterface>(
    `${API.REPORTING}/queries/${queryId}/runs/${runId}`,
  )
}

export const getQueryRunResult =
  (runId: number, queryId: number, fields?: string, dashboardId?: number | string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<QueryRunResultInterface>
  > =>
    apiWithoutHandling.get(
      dashboardId
        ? `${API.DASHBOARDS}/internal/${dashboardId}/queries/${queryId}/runs/${runId}/result`
        : `${API.REPORTING}/queries/${queryId}/runs/${runId}/result`,
      {
        params: {
          fields,
          ...filterSortPageIntoQuery(sortBy, filters, page),
        },
      },
    )

export const postQueryRunResultExport = (
  exportType: ExportTypes,
  runId: number,
  queryId: number | string,
) =>
  apiWithoutHandling.post(
    `${API.REPORTING}/queries/${queryId}/runs/${runId}/result/${exportType}`,
  )

export const getQueryRunResultExport = (
  exportType: ExportTypes,
  runId: number,
  queryId: number | string,
) =>
  apiWithoutHandling.get<{ url: string }>(
    `${API.REPORTING}/queries/${queryId}/runs/${runId}/result/${exportType}`,
  )

export const getQueryRunResultColumns = (
  runId: number,
  queryId: number,
  column_name: string,
  filters?: FilterByInterface[],
) =>
  api.get<{ value: string | null; count: number }[]>(
    `${API.REPORTING}/queries/${queryId}/runs/${runId}/result/columns/${column_name}/counts`,
    {
      params: {
        page_size: 25,
        ...filterSortPageIntoQuery(undefined, filters),
      },
    },
  )

export const queryRunsTableRequests =
  (id: number | string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<RunQueryResponseInterface>
  > =>
    api.get(`${API.REPORTING}/queries/${id}/runs`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useGetConnectionSchema = (connectionId: number | null) =>
  useFetch<{
    [key: string]: CatalogInterface
  }>(connectionId ? `/data/connections/${connectionId}/schema` : null)

export const reportRunsTableRequests =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<RunReportResponseInterface>
  > =>
    api.get(`${API.REPORTING}/reports/${id}/runs`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const reportRun = (
  reportId: number,
  data?: any,
): AxiosPromise<RunReportResponseInterface> =>
  api.post(`${API.REPORTING}/reports/${reportId}/runs`, { execution_parameters: data })

export const getReportRun = (reportId: number, runId: number) =>
  api.get<RunReportResponseInterface>(
    `${API.REPORTING}/reports/${reportId}/runs/${runId}`,
  )

export const getReportRunResultExport = (reportId: number, runId: number) =>
  apiWithoutHandling.get<{ url: string }>(
    `${API.REPORTING}/reports/${reportId}/runs/${runId}/output`,
  )

export const getReportColumnValues = async (
  queryId: number,
  runId: number,
  column: string,
  dashboardId?: number | string,
) => {
  const resp = await api.get<{ value: string }[]>(
    dashboardId
      ? `${API.DASHBOARDS}/internal/${dashboardId}/queries/${queryId}/runs/${runId}/result/columns/${column}/values`
      : `${API.REPORTING}/queries/${queryId}/runs/${runId}/result/columns/${column}/values`,
  )

  return {
    options: resp.data.map(r => ({ id: r.value, name: r.value } as OptionInterface)),
  }
}

export const saveQueryDeployments = (data: Omit<TenantQueryDeploymentInterface, 'id'>) =>
  apiWithoutHandling.post<TenantQueryDeploymentInterface>(
    `${API.TENANTS}/queryDeployments`,
    data,
  )

export const updateQueryDeployments = (
  data: Omit<TenantQueryDeploymentInterface, 'id'>,
  deploymentId: number,
) =>
  apiWithoutHandling.put<TenantQueryDeploymentInterface>(
    `${API.TENANTS}/queryDeployments/${deploymentId}`,
    data,
  )

export const getQueryResultExportUrl = ({
  queryId,
  runId,
  fileType,
}: {
  queryId: string
  runId: number
  fileType: 'xlsx' | 'csv'
}): Promise<AxiosResponse<{ url: string; format: string }>> =>
  api.get(`${API.REPORTING}/queries/${queryId}/runs/${runId}/result/${fileType}`)
