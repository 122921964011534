import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { EmployeePlansTable } from '@src/features/GrowthPlans/EmployeePlans/EmployeePlansTable'

interface Props {
  data: EmployeeInterface
  navigation?: React.ReactElement
}

export const GrowthPlans = ({ data, navigation }: Props) => {
  return <EmployeePlansTable employee={data} navigation={navigation} />
}
