import React from 'react'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { HStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { navigateTo } from '@src/actions/RouterActions'

interface Props {
  backUrl: string
}

export const GrowthPlanFormActions = ({ backUrl }: Props) => {
  const { values, submit } = useLapeContext<GrowthPlanInterface>()

  const handleSubmitClick = () => {
    delete values.status
    return submit()
  }

  const handleAfterSubmitClick = () => {
    navigateTo(backUrl)
  }

  return (
    <HStack gap="s-16">
      <NewSaveButtonWithPopup
        variant="secondary"
        successText="Growth Plan successfully created"
        useValidator
        hideWhenNoChanges={false}
        onAfterSubmit={handleAfterSubmitClick}
      >
        Save as draft
      </NewSaveButtonWithPopup>
      <NewSaveButtonWithPopup
        successText="Growth Plan successfully created"
        useValidator
        hideWhenNoChanges={false}
        onClick={handleSubmitClick}
        onAfterSubmit={handleAfterSubmitClick}
      >
        Submit
      </NewSaveButtonWithPopup>
    </HStack>
  )
}
