import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { EditButton } from '@src/features/SettingsButtons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { EntityPermissions } from '@src/store/auth/types'
import { useGetJobPostingSettings } from '@src/api/settings'
import { ApproveButton, RejectButton } from '@src/features/SettingsButtons'
import { handleError } from '@src/api'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { useGetJobDescriptionApprovals } from '@src/api/jobPosting'
import {
  useCustomHiringProcesses,
  useNavigateToJobPostingPath,
} from '@src/features/JobPostingFlow/utils'
import PublishButton from '@src/pages/Forms/JobPosting/Components/PublishButton'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { AutomationAction } from '@src/pages/Forms/JobPosting/Components/AutomationAction'
import { BookingLinkAction } from '@src/pages/Forms/JobPosting/Components/BookingLinkAction'
import { ShareCandidatesAccessAction } from '@src/pages/Forms/JobPosting/Components/ShareCandidatesAccessAction'
import { ArchiveJobPostingButton } from '@src/pages/Forms/JobPosting/Components/ArchiveJobPostingButton'

type JobPostingActionsProps = {
  onApproving: (isApproving: boolean) => void
}

const JobPostingActions = ({ onApproving }: JobPostingActionsProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams<{ id: string; specId: string }>()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const enableApprovals = jobPostingSettings?.enable_approvals
  const { refetch: refetchApproval } = useGetJobDescriptionApprovals(
    enableApprovals ? values?.id : undefined,
  )
  const canChange = !!values.field_options?.actions?.includes?.(EntityPermissions.Change)
  const canApprove =
    values?.approval_status === ApprovalStatuses.Pending &&
    values?.field_options?.actions?.includes(EntityPermissions.Approve)
  const navigateToJobPosting = useNavigateToJobPostingPath()
  const customHiringProcesses = useCustomHiringProcesses()
  const onBeforeApproval = () => onApproving(true)
  const onAfterApproval = () => {
    onApproving(false)
    refetchApproval()
  }
  return (
    <MoreBar maxCount={3} labelMoreButton="More job posting actions">
      {canApprove && (
        <>
          <ApproveButton
            isVisible={enableApprovals}
            onBeforeSubmit={onBeforeApproval}
            onAfterSubmit={onAfterApproval}
            onSubmitFailed={handleError}
            statusFieldName="approval_status"
          />
          <RejectButton
            isVisible={enableApprovals}
            onBeforeSubmit={onBeforeApproval}
            onAfterSubmit={onAfterApproval}
            onSubmitFailed={handleError}
            statusFieldName="approval_status"
            dialog={{
              title: 'Reason for rejection',
              placeholder: 'a couple of words',
              fieldName: 'rejection_comment',
            }}
          />
        </>
      )}
      {!customHiringProcesses && <PublishButton />}
      <EditButton
        onClick={() => {
          navigateToJobPosting(
            {
              id: params.id,
              specialisationId: params.specId,
              name: values.name,
            },
            ROUTES.FORMS.JOB_POSTING_FLOW.PUBLISH,
          )
        }}
        isVisible={canChange}
      />
      <MoreBar.Action
        useIcon="Questionnaire"
        use={InternalLink}
        to={pathToUrl(ROUTES.FORMS.JOB_POSTING.APPLICATION_FORM, params)}
      >
        Application form
      </MoreBar.Action>
      <AutomationAction />
      <BookingLinkAction />
      <ShareCandidatesAccessAction />
      <ArchiveJobPostingButton />
    </MoreBar>
  )
}

export default JobPostingActions
