import React from 'react'
import { Checkbox, Item, Text, VStack } from '@revolut/ui-kit'
import { GrowthPlanActionInterface } from '@src/interfaces/growthPlans'

interface Props {
  action: GrowthPlanActionInterface
  onClick: VoidFunction
}

export const ActionItem = ({ action, onClick }: Props) => {
  return (
    <Item use="label" onClick={onClick}>
      <Item.Prefix>
        <Checkbox value={action.id} checked={action.is_completed} />
      </Item.Prefix>
      <Item.Content>
        <Item.Title>
          <VStack>
            {action.description.split('\n').map((line, index) => (
              <Text key={index}>{line.trim()}</Text>
            ))}
          </VStack>
        </Item.Title>
      </Item.Content>
    </Item>
  )
}
