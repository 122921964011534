import React from 'react'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { HStack } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'

interface Props {
  backUrl: string
}

export const GrowthPlanFormActions = ({ backUrl }: Props) => {
  const handleAfterSubmitClick = () => {
    navigateTo(backUrl)
  }

  return (
    <HStack gap="s-16">
      <NewSaveButtonWithPopup
        useValidator
        successText="Growth Plan successfully updated"
        onAfterSubmit={handleAfterSubmitClick}
      >
        Save changes
      </NewSaveButtonWithPopup>
    </HStack>
  )
}
