import React, { FormEvent } from 'react'
import {
  ActionButton,
  HStack,
  Icon,
  Spacer,
  Tag,
  Text,
  TextArea,
  TextButton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { GrowthPlanActionInterface } from '@src/interfaces/growthPlans'
import { obfuscateNonPrintableCharacters } from '@src/utils/string'

interface Props {
  action: GrowthPlanActionInterface
  hasError: boolean
  errorMessage?: string
  onChange: (value: string) => void
  onDelete: () => void
  dataName: string
}

export const ActionItem = ({
  action,
  hasError,
  errorMessage,
  onChange,
  onDelete,
  dataName,
}: Props) => {
  return (
    <VStack
      p="s-16"
      space="s-16"
      borderRadius="r16"
      border={`1px solid ${Token.color.grey50_20}`}
    >
      <HStack space="s-16" align="center">
        <Tag>
          <TextButton color={Token.color.white} disabled>
            <HStack p="s-4" align="center" space="s-6">
              <Icon name="Plus" size={12} />
              <Text>Connect skill or value</Text>
            </HStack>
          </TextButton>
        </Tag>
        <Spacer />
        <ActionButton useIcon="Delete" iconOnly onClick={onDelete}>
          Delete
        </ActionButton>
      </HStack>
      <TextArea
        label="To-do"
        maxRows={3}
        value={action.description}
        invalid={hasError}
        errorMessage={errorMessage}
        onChange={({ currentTarget }: FormEvent<HTMLTextAreaElement>) =>
          onChange(obfuscateNonPrintableCharacters(currentTarget.value))
        }
        data-name={dataName}
      />
    </VStack>
  )
}
