import { companyKpiRequests, kpisRequests } from '@src/api/kpis'
import { BudgetTypes } from '@src/interfaces/budgets'
import { getBudgetsForDepartment, getTalent } from '@src/api/budgets'
import { getDirectReportsShifts, getTeamScheduleShifts } from '@src/api/attendance'
import { EmployeeShiftChildrenType } from '@src/interfaces/attendance'
import { getRoadmapChildIssues } from '@src/api/roadmaps'
import { RoadmapChildrenTypes } from '@src/interfaces/roadmaps'

export enum KPITypes {
  company_kpi = 'company_kpi',
  department_kpi = 'department_kpi',
  team_kpi = 'team_kpi',
  function_kpi = 'function_kpi',
  employee_kpi = 'employee_kpi',
  company_goal = 'company_goal',
  role_kpi = 'role_kpi',
  cascaded_kpi = 'cascaded',
}

export const childrenRequests = {
  [BudgetTypes.TEAM_BUDGET]: { getItems: getBudgetsForDepartment },
  [BudgetTypes.EMPLOYEE_BUDGET]: { getItems: getTalent },
  [KPITypes.company_kpi]: companyKpiRequests,
  [KPITypes.department_kpi]: kpisRequests,
  [KPITypes.team_kpi]: kpisRequests,
  [KPITypes.employee_kpi]: kpisRequests,
  [KPITypes.function_kpi]: kpisRequests,
  [KPITypes.role_kpi]: kpisRequests,
  [EmployeeShiftChildrenType.DirectReportShifts]: { getItems: getDirectReportsShifts },
  [EmployeeShiftChildrenType.TeamShifts]: { getItems: getTeamScheduleShifts },
  [RoadmapChildrenTypes.EMPLOYEE_ROADMAP]: { getItems: getRoadmapChildIssues },
  [RoadmapChildrenTypes.DEPARTMENT_ROADMAP]: { getItems: getRoadmapChildIssues },
  [RoadmapChildrenTypes.FUNCTION_ROADMAP]: { getItems: getRoadmapChildIssues },
  [RoadmapChildrenTypes.TEAM_ROADMAP]: { getItems: getRoadmapChildIssues },
  [RoadmapChildrenTypes.COMPANY_ROADMAP]: { getItems: getRoadmapChildIssues },
}

export enum TableNames {
  Meetings = 'Meetings',
  EmployeeMeetings = 'EmployeeMeetings',
  MeetingsTracker = 'MeetingsTracker',
  ProbationGoals = 'ProbationGoals',
  JiraLegacyProbaitonGoals = 'JiraLegacyProbaitonGoals',
  OneToOnes = 'OneToOnes',
  AffectedCandidates = 'AffectedCandidates',
  AllDocuments = 'AllDocuments',
  AppliedPostings = 'AppliedPostings',
  ApproveKPIs = 'ApproveKPIs',
  AssignKPIs = 'AssignKPIs',
  PerformanceKPIsSummary = 'PerformanceKPIsSummary',
  AssignKPIsEditable = 'AssignKPIsEditable',
  AssignedGroupsUsers = 'AssignedGroupsUsers',
  AssignedPermissionsUsers = 'AssignedPermissionsUsers',
  AttendancePolicies = 'AttendancePolicies',
  AttendanceSchedule = 'AttendanceSchedule',
  AuditTickets = 'AuditTickets',
  BenchmarkChangelog = 'BenchmarkChangelog',
  Benefits = 'Benefits',
  BenefitsEnrolment = 'BenefitsEnrolment',
  BenefitsTemplates = 'BenefitsTemplates',
  Bonuses = 'Bonuses',
  Budget = 'Budget',
  BudgetDistribution = 'BudgetDistribution',
  BudgetImpact = 'BudgetImpact',
  BudgetSpend = 'BudgetSpend',
  BulkRequestDocumentsEligibleEmployees = 'BulkRequestDocumentsEligibleEmployees',
  CVCriterias = 'CVCriterias',
  CXBugs = 'CXBugs',
  CXComplaints = 'CXComplaints',
  CXData = 'CXData',
  CXNetContacts = 'CXNetContacts',
  CXSupportNPS = 'CXSupportNPS',
  CXTRustpilotTickets = 'CXTRustpilotTickets',
  CandidateExpectations = 'CandidateExpectations',
  CandidateHiringStages = 'CandidateHiringStages',
  Candidates = 'Candidates',
  ImportCandidatesV2 = 'ImportCandidatesV2',
  ImportJobsV2 = 'ImportJobsV2',
  ImportGoals = 'ImportGoals',
  CommonCandidates = 'CommonCandidates',
  CommonRequisition = 'CommonRequisition',
  CommunicationGroups = 'CommunicationGroups',
  CompaniesList = 'CompaniesList',
  CompanyBudgetEditable = 'CompanyBudgetEditable',
  CompanyCulturalSkills = 'CompanyCulturalSkills',
  CompanyGoalsPreview = 'CompanyGoalsPreview',
  CompanyKPI = 'CompanyKPI',
  CompanyKPIDepartment = 'CompanyKPIDepartment',
  CompanyKPIDrafts = 'CompanyKPIDrafts',
  CompanyKPIGoals = 'CompanyKPIGoals',
  CompanyKPIMandatory = 'CompanyKPIMandatory',
  CompanyKPIPending = 'CompanyKPIPending',
  CompanyRoadmap = 'CompanyRoadmap',
  CompensationBenchmarks = 'CompensationBenchmarks',
  CompensationBonuses = 'CompensationBonuses',
  CompensationBudgetPools = 'CompensationBudgetPools',
  CompensationReviewCycles = 'CompensationReviewCycles',
  CompetencyMatrix = 'CompetencyMatrix',
  ContractChangelog = 'ContractChangelog',
  Contracts = 'Contracts',
  ContractTypes = 'ContractTypes',
  Controls = 'Controls',
  Culture = 'Culture',
  CultureValues = 'CultureValues',
  CustomFields = 'CustomFields',
  DataRetentionSettings = 'DataRetentionSettings',
  DepartmentAuditTickets = 'DepartmentAuditTickets',
  DepartmentBudgetEditable = 'DepartmentBudgetEditable',
  DepartmentKPIDrafts = 'DepartmentKPIDrafts',
  DepartmentKPIs = 'DepartmentKPIs',
  DepartmentKarma = 'DepartmentKarma',
  DepartmentLeadership = 'DepartmentLeadership',
  DepartmentNPS = 'DepartmentNPS',
  DepartmentPendingKPIs = 'DepartmentPendingKPIs',
  DepartmentRoadmap = 'DepartmentRoadmap',
  DepartmentSchedule = 'DepartmentSchedule',
  DepartmentTeams = 'DepartmentTeams',
  DepartmentTimeOff = 'DepartmentTimeOff',
  DepartmentTimeOffCalendar = 'DepartmentTimeOffCalendar',
  Departments = 'Departments',
  DepartmentsStructure = 'DepartmentsStructure',
  DepartmentsFilter = 'DepartmentsFilter',
  DocumentsOnboarding = 'DocumentsOnboarding',
  Goals = 'Goals',
  TeamsStructure = 'TeamsStructure',
  RolessStructure = 'RolessStructure',
  DocumentCategories = 'DocumentCategories',
  DocumentRules = 'DocumentRules',
  DocumentTemplates = 'DocumentTemplates',
  DocumentCollections = 'DocumentCollections',
  DynamicGroups = 'DynamicGroups',
  ManageDynamicGroups = 'ManageDynamicGroups',
  DynamicGroupsAudience = 'DynamicGroupsAudience',
  EligibleEmployees = 'EligibleEmployees',
  EmailPlaceholders = 'EmailPlaceholders',
  EmailTemplates = 'EmailTemplates',
  EmployeeAccessRequests = 'EmployeeAccessRequests',
  EmployeeAuditTickets = 'EmployeeAuditTickets',
  EmployeeBenefitChangelog = 'EmployeeBenefitChangelog',
  EmployeeChangeRequests = 'EmployeeChangeRequests',
  EmployeeChangelog = 'EmployeeChangelog',
  EmployeeContracts = 'EmployeeContracts',
  EmployeeCulture = 'EmployeeCulture',
  EmployeeDataOwnership = 'EmployeeDataOwnership',
  EmployeeDeliverables = 'EmployeeDeliverables',
  EmployeeDocuments = 'EmployeeDocuments',
  EmployeeEmailPlaceholders = 'EmployeeEmailPlaceholders',
  EmployeeEmailTemplates = 'EmployeeEmailTemplates',
  EmployeeFieldsChangelog = 'EmployeeFieldsChangelog',
  EmployeeJiraOwnership = 'EmployeeJiraOwnership',
  EmployeeKPIDrafts = 'EmployeeKPIDrafts',
  EmployeeKPIs = 'EmployeeKPIs',
  KeyPersonEmployeeResponsibilities = 'KeyPersonEmployeeResponsibilities',
  KeyPersonEmployeeOwnershipResponsibilitiesList = 'KeyPersonEmployeeOwnershipResponsibilitiesList',
  EmployeeLabels = 'EmployeeLabels',
  EmployeeLinkedAccounts = 'EmployeeLinkedAccounts',
  EmployeeOwnership = 'EmployeeOwnership',
  EmployeeOwnershipTransfer = 'EmployeeOwnershipTransfer',
  EmployeePerformanceKPI = 'EmployeePerformanceKPI',
  EmployeePerformanceRoadmap = 'EmployeePerformanceRoadmap',
  EmployeePerformanceTimeline = 'EmployeePerformanceTimeline',
  EmployeePerformanceSkills = 'EmployeePerformanceSkills',
  EmployeePersonalKPIs = 'EmployeePersonalKPIs',
  EmployeeReferrals = 'EmployeeReferrals',
  EmployeeRelevantKPIs = 'EmployeeRelevantKPIs',
  EmployeeRequisitions = 'EmployeeRequisitions',
  EmployeeRightToWork = 'EmployeeRightToWork',
  EmployeeRoadmap = 'EmployeeRoadmap',
  EmployeeRoadmapItems = 'EmployeeRoadmapItems',
  EmployeeScreening = 'EmployeeScreening',
  EmployeeTalent = 'EmployeeTalent',
  EmployeeTimeOffBalances = 'EmployeeTimeOffBalances',
  EmployeeTimeOffChangelog = 'EmployeeTimeOffChangelog',
  EmployeeTimeOffPolicies = 'EmployeeTimeOffPolicies',
  ManageTimeOffPolicies = 'ManageTimeOffPolicies',
  TempTimeOffPolicyAssignments = 'TempTimeOffPolicyAssignments',
  EmployeeTimeOffRequests = 'EmployeeTimeOffRequests',
  EmployeeVendorOwnership = 'EmployeeVendorOwnership',
  Employees = 'Employees',
  PeopleTimeOffCalendar = 'PeopleTimeOffCalendar',
  SubscriptionInvoices = 'SubscriptionInvoices',
  SubscriptionPlans = 'SubscriptionPlans',
  AccountInvoices = 'AccountInvoices',
  EngagementQuestions = 'EngagementQuestions',
  EngagementDrivers = 'EngagementDrivers',
  EngagementSurveys = 'EngagementSurveys',
  EngagementSurveysResultQuestions = 'EngagementSurveysResultQuestions',
  EngagementSurveysResultCategories = 'EngagementSurveysResultCategories',
  EngagementSurveysResultComments = 'EngagementSurveysResultComments',
  EngagementSurveyHistory = 'EngagementSurveyHistory',
  EngagementDriversResults = 'EngagementDriversResults',
  EngagementQuestionsResults = 'EngagementQuestionsResults',
  Entities = 'Entities',
  EntitiesStructure = 'EntitiesStructure',
  EntityPaygroups = 'EntityPaygroups',
  EntityRegions = 'EntityRegions',
  Feedback = 'Feedback',
  Findings = 'Findings',
  FindingsSummary = 'FindingsSummary',
  FunctionHierarchy = 'FunctionHierarchy',
  FunctionKPIs = 'FunctionKPIs',
  FunctionPlaybooks = 'FunctionPlaybooks',
  FunctionRoadmap = 'FunctionRoadmap',
  FunctionRoles = 'FunctionRoles',
  FunctionSpecialisations = 'FunctionSpecialisations',
  Functions = 'Functions',
  GradeCalibration = 'GradeCalibration',
  Groups = 'Groups',
  Hires = 'Hires',
  Hiring = 'Hiring',
  HiringProcess = 'HiringProcess',
  ApprovalFlow = 'ApprovalFlow',
  ProbationManagers = 'ProbationManagers',
  PIPManagers = 'PIPManagers',
  InviteTeam = 'InviteTeam',
  HiringScorecardCompetencyMatrix = 'HiringScorecardCompetencyMatrix',
  HiringScorecardSummary = 'HiringScorecardSummary',
  InterviewTemplateScorecards = 'InterviewTemplateScorecards',
  HiringSummary = 'HiringSummary',
  ImportBonuses = 'ImportBonuses',
  ImportDepartmentBudget = 'ImportDepartmentBudget',
  ImportDocumentsV2 = 'ImportDocumentsV2',
  ImportExternalEmployees = 'ImportExternalEmployees',
  ImportEmployees = 'ImportEmployees',
  ImportEmployeesV2 = 'ImportEmployeesV2',
  ImportContractsV2 = 'ImportContractsV2',
  InviteEmployees = 'InviteEmployees',
  TeamsOnboarding = 'TeamsOnboarding',
  RolesOnboarding = 'RolesOnboarding',
  ImportFAQ = 'ImportFAQ',
  ImportCompensationBands = 'ImportCompensationBands',
  Incidents = 'Incidents',
  Indicators = 'Indicators',
  InterviewProcess = 'InterviewProcess',
  Interviews = 'Interviews',
  BulkActions = 'Bulk actions',
  JobPostings = 'JobPostings',
  JobPostingRequisitions = 'JobPostingRequisitions',
  IndeedPostings = 'IndeedPostings',
  LinkedInPostings = 'LinkedInPostings',
  JobPostingFlowDetailsRequisition = 'JobPostingFlowDetailsRequisition',
  KeyPersonsResponsibilities = 'KeyPersonsResponsibilities',
  KeyPersonsSuccession = 'KeyPersonsSuccession',
  KeyPersonsSuccessionPlans = 'KeyPersonsSuccessionPlans',
  KeyPersonsSuccessionPlansChangelog = 'KeyPersonsSuccessionPlansChangelog',
  KeyPersonsEmployeeResponsibilityChangelog = 'KeyPersonsEmployeeResponsibilityChangelog',
  KPIChangelog = 'KPIChangelog',
  KPIImportReview = 'KPIImportReview',
  KPIs = 'KPIs',
  KPIsSummary = 'KPIsSummary',
  KpiTemplates = 'KpiTemplates',
  KpiTemplatesKPIRules = 'KpiTemplatesKPIRules',
  KpiTemplatesRules = 'KpiTemplatesRules',
  LeadershipSummary = 'LeadershipSummary',
  Locations = 'Locations',
  FaqCategories = 'FaqCategories',
  FaqSDCategories = 'FaqSDCategories',
  NewBenefits = 'NewBenefits',
  Notifications = 'Notifications',
  NotificationPlaceholders = 'NotificationPlaceholders',
  NotificationsTemplateAudience = 'NotificationsTemplateAudience',
  NotificationsTemplateLogs = 'NotificationsTemplateLogs',
  Offboarding = 'Offboarding',
  OffboardingTickets = 'OffboardingTickets',
  OfferChangelog = 'OfferChangelog',
  OfferChecklist = 'OfferChecklist',
  OfferExistingPlaceholders = 'OfferExistingPlaceholders',
  OfferNewPlaceholders = 'OfferNewPlaceholders',
  OfferPlaceholders = 'OfferPlaceholders',
  OfferTemplates = 'OfferTemplates',
  Offers = 'Offers',
  Onboarding = 'Onboarding',
  PayGroups = 'PayGroups',
  PayCycles = 'PayCycles',
  ExerciseCycles = 'ExerciseCycles',
  Payments = 'Payments',
  PayCycleEmployeeReports = 'PayCycleEmployeeReports',
  OptionPayments = 'OptionPayments',
  PaymentsReconciliation = 'PaymentsReconciliation',
  PaymentsBulkDelete = 'PaymentsBulkDelete',
  PayCycleReports = 'PayCycleReports',
  PerformanceHistory = 'PerformanceHistory',
  PerformanceSummary = 'PerformanceSummary',
  PerformanceReviews = 'PerformanceReviews',
  PerformanceSummaryDeliverables = 'PerformanceSummaryDeliverables',
  PerformanceSummaryKPIs = 'PerformanceSummaryKPIs',
  PerformanceSummarySkills = 'PerformanceSummarySkills',
  PerformanceSummaryManagerSkills = 'PerformanceSummaryManagerSkills',
  PerformanceSummaryValues = 'PerformanceSummaryValues',
  PerformanceSummaryBarRaiser = 'PerformanceSummaryBarRaiser',
  PerformanceTimeline = 'PerformanceTimeline',
  PerformanceTimelineSidebar = 'PerformanceTimelineSidebar',
  PermissionTransfer = 'PermissionTransfer',
  Permissions = 'Permissions',
  PostingAutomationRules = 'PostingAutomationRules',
  Postings = 'Postings',
  ProbationEmployees = 'ProbationEmployees',
  ProbationTemplates = 'ProbationTemplates',
  PipEmployees = 'PipEmployees',
  ProbationKPI = 'ProbationKPI',
  PromotionNominees = 'PromotionNominees',
  PromotionTimeline = 'PromotionTimeline',
  PublicHolidays = 'PublicHolidays',
  RecruitersAssigned = 'RecruitersAssigned',
  RecruitmentGroups = 'RecruitmentGroups',
  ReferralBonus = 'ReferralBonus',
  RequisitionChangelog = 'RequisitionChangelog',
  RequisitionsFilter = 'RequisitionsFilter',
  Resignations = 'Resignations',
  ReviewCycles = 'ReviewCycles',
  Rewards = 'Rewards',
  RiskSummary = 'RiskSummary',
  RoadmapSummary = 'RoadmapSummary',
  RoadmapTargets = 'RoadmapTargets',
  RoadmapsMain = 'RoadmapsMain',
  RoleHiringStages = 'RoleHiringStages',
  RoleKPI = 'RoleKPI',
  RoleOwners = 'RoleOwners',
  RoleSpecialisations = 'RoleSpecialisations',
  Roles = 'Roles',
  Salary = 'Salary',
  SalarySacrifice = 'SalarySacrifice',
  Schedule = 'Schedule',
  ScheduleShiftChangelog = 'ScheduleShiftChangelog',
  ScheduledShifts = 'ScheduledShifts',
  SchedulingShifts = 'SchedulingShifts',
  Screenings = 'Screenings',
  SendingHistory = 'SendingHistory',
  Seniorities = 'Seniorities',
  SentDocuments = 'SentDocuments',
  SentRequests = 'SentRequests',
  ShiftBreakdown = 'ShiftBreakdown',
  ShiftSummary = 'ShiftSummary',
  SkillSpecialisations = 'SkillSpecialisations',
  SkillTalent = 'SkillTalent',
  Skills = 'Skills',
  SlackBots = 'SlackBots',
  SpecialisationHiringProcess = 'SpecialisationHiringProcess',
  SpecialisationHiringStages = 'SpecialisationHiringStages',
  SpecialisationRequisitions = 'SpecialisationRequisitions',
  SpecialisationSeniorityLevels = 'SpecialisationSeniorityLevels',
  Specialisations = 'Specialisations',
  SupportFaq = 'SupportFaq',
  SupportFaqTopics = 'SupportFaqTopics',
  Talent = 'Talent',
  TalentPerformance = 'TalentPerformance',
  TalentPromotionNominations = 'TalentPromotionNominations',
  TalentSummary = 'TalentSummary',
  TeamAndFunctionBudget = 'TeamAndFunctionBudget',
  TeamAuditTickets = 'TeamAuditTickets',
  TeamDataAccess = 'TeamDataAccess',
  TeamKPI = 'TeamKPI',
  TeamKPIDrafts = 'TeamKPIDrafts',
  TeamNPS = 'TeamNPS',
  TeamPendingKPI = 'TeamPendingKPI',
  TeamRoadmap = 'TeamRoadmap',
  TeamTimeOff = 'TeamTimeOff',
  TeamTimeOffCalendar = 'TeamTimeOffCalendar',
  Teams = 'Teams',
  TemplateTargets = 'TemplateTargets',
  TimeOffBalances = 'TimeOffBalances',
  TimeOffCategories = 'TimeOffCategories',
  TimeOffPolicies = 'TimeOffPolicies',
  TimeOffRegimes = 'TimeOffRegimes',
  TimeOffRequests = 'TimeOffRequests',
  TimeOffPoliciesReview = 'TimeOffPoliciesReview',
  WorkScheduleEligibilityReview = 'WorkScheduleEligibilityReview',
  WorkSchedules = 'WorkSchedules',
  WorkSchedulesCustomApprovers = 'WorkSchedulesCustomApprovers',
  UploadTimeOffRequests = 'UploadTimeOffRequests',
  UploadTimeOffBalances = 'UploadTimeOffBalances',
  UploadTimeOffProjectedBalances = 'UploadTimeOffProjectedBalances',
  UploadedDocuments = 'UploadedDocuments',
  BulkUploadDocuments = 'BulkUploadDocuments',
  UserNotifications = 'UserNotifications',
  UserTimeOffRegimes = 'UserTimeOffRegimes',
  UsersAccess = 'UsersAccess',
  SendInvitationEmails = 'SendInvitationEmails',
  ValueTargets = 'ValueTargets',
  GlobalAccounts = 'GlobalAccounts',
  Whitelist = 'Whitelist',
  EmployeesBulkEdit = 'EmployeesBulkEdit',
  ContractsBulkEdit = 'ContractsBulkEdit',
  SelectContracts = 'SelectContracts',
  PaymentsUpload = 'PaymentsUpload',
  EligibleGroups = 'EligibleGroups',
  ReportingAppQueries = 'ReportingAppQueries',
  ReportingAppConnections = 'ReportingAppConnections',
  ReportingAppReports = 'ReportingAppReports',
  ReportingAppConnectionsPermissions = 'ReportingAppConnectionsPermissions',
  ReportingAppDashboardsPermissions = 'ReportingAppDashboardsPermissions',
  ReportingAppQueriesPermissions = 'ReportingAppQueriesPermissions',
  ReportingAppQueriesHistoricalRuns = 'ReportingAppQueriesHistoricalRuns',
  ReportingAppQueriesRunResult = 'ReportingAppQueriesRunResult',
  ReportingAppReportsFormatColumns = 'ReportingAppReportsFormatColumns',
  ReportingAppReportsViewColumns = 'ReportingAppReportsViewColumns',
  ReportingAppReportsViewParameters = 'ReportingAppReportsViewParameters',
  ReportingAppReportsHistoricalRuns = 'ReportingAppReportHistoricalRuns',
  ReportingAppReportsUITable = 'ReportingAppReportsUITable',
  ReportingAppReportsPreviewTable = 'ReportingAppReportsPreviewTable',
  BookingLinks = 'BookingLinks',
  CRM = 'CRM',
  CountryHiring = 'CountryHiring',
  ImportRequisitions = 'ImportRequisitions',
  ImportPerformance = 'ImportPerformance',
  ImportJobPostings = 'ImportJobPostings',
  ImportCandidates = 'ImportCandidates',
  ImportContracts = 'ImportContracts',
  AnalyticsDashboards = 'AnalyticsDashboards',
  SpecialisationsHiringProcessOverview = 'SpecialisationsHiringProcessOverview',
  ProbationTemplateEligibleEmployees = 'ProbationTemplateEligibleEmployees',
  TeamIssues = 'TeamIssues',
  DepartmentIssues = 'DepartmentIssues',
  CompanyIssues = 'CompanyIssues',
  OnboardingJobsPreview = 'OnboardingJobsPreview',
  AdpChangeReports = 'AdpChangeReports',
  InterviewAvailability = 'InterviewAvailability',
  InvitationEmailPlaceholders = 'InvitationEmailPlaceholders',
  PerformanceHiringSummaryDeliverables = 'PerformanceHiringSummaryDeliverables',
  PerformanceHiringSummarySkills = 'PerformanceHiringSummarySkills',
  PerformanceHiringSummaryValues = 'PerformanceHiringSummaryValues',
  ProbationRecommendationTable = 'ProbationRecommendationTable',
  ObsoletePerformanceReview = 'ObsoletePerformanceReview',
  EmployeeCardTable = 'EmployeeCardTable',
  MeetingTemplates = 'MeetingTemplates',
  TotalCompensationGrants = 'TotalCompensationGrants',
  UploadCashBonuses = 'UploadCashBonuses',
  UploadGrants = 'UploadGrants',
  UploadBands = 'UploadBands',
  StockGrants = 'StockGrants',
  CandidateScoreExplanation = 'CandidateScoreExplanation',
  CompanyMeetings = 'CompanyMeetings',
  DocusignIntegrations = 'DocusignIntegrations',
  EmployeeGrowthPlans = 'EmployeeGrowthPlans',
}
