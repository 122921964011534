import React, { useEffect, useState } from 'react'
import { Box, chain, Header, MoreBar } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import { connect } from 'lape'
import JobDescription from '@src/pages/Forms/JobPosting/Components/JobPosting'
import { jobPostingsRequests } from '@src/api/jobPosting'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import TextBlocksSkeleton from '@components/Skeletons/TextBlocksSkeleton'
import upperFirst from 'lodash/upperFirst'
import { Status } from '@components/CommonSC/General'
import { useNavigateToJobPostingPath } from '@src/features/JobPostingFlow/utils'
import { ROUTES } from '@src/constants/routes'

export interface CriteriaSidebarFormProps {
  isOpen: boolean
  onClose: () => void
  postingId: number | null
}

const PreviewJobPostingSidebar = ({
  isOpen,
  onClose,
  postingId,
}: CriteriaSidebarFormProps) => {
  const [loading, setLoading] = useState(true)
  const [jobPosting, setJobPosting] = useState<JobPostingInterface>()
  const navigateToJobPosting = useNavigateToJobPostingPath()

  const fetchJobPosting = async () => {
    if (!postingId) {
      return
    }
    setLoading(true)
    try {
      const resp = await jobPostingsRequests.getItem(postingId)

      if (resp?.data) {
        setJobPosting(resp?.data)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchJobPosting()
  }, [postingId])

  return (
    <>
      <SideBar
        isOpen={isOpen}
        variant="wide"
        title={
          jobPosting?.approval_status
            ? chain(
                jobPosting?.name,
                <Status status={jobPosting?.approval_status}>
                  {upperFirst(jobPosting?.approval_status)}
                </Status>,
              )
            : jobPosting?.name
        }
        onClose={onClose}
        headerContent={
          <Header.Bar>
            <MoreBar>
              <MoreBar.Action
                useIcon="Pencil"
                onClick={() => {
                  navigateToJobPosting(
                    {
                      id: jobPosting?.id,
                      specialisationId: jobPosting?.specialisation?.id,
                    },
                    ROUTES.FORMS.JOB_POSTING_FLOW.PUBLISH,
                  )
                }}
              >
                Edit
              </MoreBar.Action>
            </MoreBar>
          </Header.Bar>
        }
        loading={loading}
      >
        <Box mb="s-32">
          {loading ? (
            <TextBlocksSkeleton />
          ) : (
            <JobDescription id={jobPosting?.id} locations={jobPosting?.locations} />
          )}
        </Box>
      </SideBar>
    </>
  )
}

export default connect(PreviewJobPostingSidebar)
