import { Avatar, Group, Icon, Item, ItemSkeleton, Text, Token } from '@revolut/ui-kit'
import React, { useEffect } from 'react'
import { format } from 'date-fns'
import {
  GoogleCalendarEventAttendee,
  MeetingEvent,
  MeetingEventStatus,
} from '@src/interfaces/meetings'
import { useGetEmployeeMeetingEvents } from '@src/api/meetings'
import { meetingDuration } from './utils/meetingDuration'
import { EmptyTableRaw as EmptyState } from '@src/components/Table/EmptyTableRaw'

interface Props {
  selectedMeeting: MeetingEvent<GoogleCalendarEventAttendee> | undefined
  setSelectedMeeting: (
    target: MeetingEvent<GoogleCalendarEventAttendee> | undefined,
  ) => void
  isLoading: boolean
  managerId: number
  employeeId: number
  startDate: string
  endDate: string
  setMeetingList: (list: MeetingEvent<GoogleCalendarEventAttendee>[]) => void
}

const Skeleton = () => {
  return (
    <Group>
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
    </Group>
  )
}

export const MeetingsList = ({
  selectedMeeting,
  setSelectedMeeting,
  setMeetingList,
  isLoading,
  managerId,
  employeeId,
  startDate,
  endDate,
}: Props) => {
  const { data, isLoading: isMeetingEventsLoading } = useGetEmployeeMeetingEvents({
    employee_id: managerId,
    attendees: [employeeId, managerId].join(','),
    is_assigned: true,
    is_series: false,
    start: `${startDate},${endDate}`,
  })

  useEffect(() => {
    if (data?.results) {
      setSelectedMeeting(data.results[0])
      setMeetingList(data.results)
    }
  }, [data])

  const events = data?.results
  if (isLoading || isMeetingEventsLoading) {
    return <Skeleton />
  }

  const isEventsAvailable = Boolean(events?.length && events.length > 0)

  return (
    <Group>
      {!isMeetingEventsLoading && !isEventsAvailable && (
        <EmptyState title="You don't have any meeting for this cycle" />
      )}
      {isEventsAvailable &&
        events?.map(event => (
          <Item
            key={event.id}
            use="button"
            variant="compact"
            aria-pressed={selectedMeeting?.id === event.id}
            onClick={() => {
              setSelectedMeeting(event)
            }}
          >
            <Item.Avatar>
              <Avatar
                color={Token.color.foreground}
                useIcon="Calendar"
                textStyle="emphasis1"
              >
                {selectedMeeting?.id === event.id && (
                  <Avatar.Badge
                    bg={Token.color.foreground}
                    position="bottom-right"
                    useIcon={<Icon name="Check" size={12} />}
                  />
                )}
              </Avatar>
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{format(new Date(event.start), 'MMM d')}</Item.Title>
              <Item.Description
                color={
                  event.status === MeetingEventStatus.Completed
                    ? Token.color.greyTone50
                    : Token.color.deepGrey
                }
              >
                <Text variant="body2">{event.status}</Text>
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <Item.Value>
                <Text variant="body1" color={Token.color.greyTone50}>
                  {meetingDuration(event.start, event.end)}
                </Text>
              </Item.Value>
            </Item.Side>
          </Item>
        ))}
    </Group>
  )
}
