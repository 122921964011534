import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
}

export const ManagerActions = ({ employee, growthPlan }: Props) => {
  const handleEditGrowthPlan = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.GROWTH_PLAN.EDIT, {
        employeeId: employee.id,
        id: growthPlan.id,
      }),
    )
  }

  // TODO: add additional actions in scope of https://revolut.atlassian.net/browse/REVC-7491
  return (
    <>
      <MoreBar.Action useIcon="Pencil" onClick={handleEditGrowthPlan}>
        Edit
      </MoreBar.Action>
    </>
  )
}
