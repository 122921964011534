import React, { useEffect, useMemo, useState } from 'react'
import AnalyticsSkeleton from '@src/pages/Forms/DepartmentForm/Performance/Analytics/AnalyticsSkeleton'
import { Box, Flex, Group, TabBar, Token } from '@revolut/ui-kit'
import { Cycle } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import { YearSelectButton } from '@src/pages/Forms/DepartmentForm/Performance/Analytics/YearSelectButton'
import { BaseChartInner } from '@components/Charts/BaseChart/BaseChartInner'
import { fetchEntityRoadmapGraph } from '@src/api/roadmaps'
import { fetchEntityGoalsGraphByMonths } from '@src/api/goals'
import { PerformanceTimeRange } from '@src/constants/api'
import { PerformanceChartSingleData } from '@src/interfaces/chart'
import { formatPercentage } from '@src/utils/format'
import styled from 'styled-components'
import { curveStepAfter, line } from 'd3-shape'
import { scaleLinear } from 'd3-scale'

interface PerformanceAnalyticsProps {
  cyclesList: Cycle[]
  setSelectedCycle: (cycle: Cycle) => void
  entityId: number
}

interface Bar {
  data: {
    data: PerformanceChartSingleData<number>
  }
}

enum Tabs {
  Goals = 'goals',
  Roadmap = 'roadmap',
}

const TooltipContainer = styled.div`
  color: ${Token.color.foreground};
`

export const PerformanceAnalytics = ({
  cyclesList,
  setSelectedCycle,
  entityId,
}: PerformanceAnalyticsProps) => {
  const [currentCycle, setCurrentCycle] = useState<Cycle>(cyclesList[0])
  const [navigationTabValue, setNavigationTabValue] = useState<Tabs | null>(Tabs.Goals)
  const [chartData, setChartData] = useState<PerformanceChartSingleData<number>[]>([])
  const onChange = (option: Cycle) => {
    setCurrentCycle(option)
    setSelectedCycle(option)
  }

  const yearOptions = useMemo(
    () =>
      cyclesList
        ?.filter(cycle => cycle.type === 'year')
        .map(year => ({
          label: year.name,
          key: year.id,
          value: year,
        })),
    [cyclesList],
  )

  const getChartData = () => {
    return navigationTabValue === Tabs.Goals
      ? fetchEntityGoalsGraphByMonths('departments')(
          entityId,
          PerformanceTimeRange.month,
          currentCycle.analytics_offset!,
        )
      : fetchEntityRoadmapGraph('departments')(
          entityId,
          PerformanceTimeRange.month,
          currentCycle.analytics_offset!,
        )
  }

  useEffect(() => {
    getChartData().then(data => {
      setChartData(data.data.progress_history.map(i => ({ ...i, expected: 1 })))
    })
  }, [currentCycle, navigationTabValue])

  const generateExpactedLayer = (lineColor: string) => {
    return ({ bars, width, xScale, yScale }: any) => {
      if (!bars?.length) {
        return null
      }
      const extraBars = bars.concat(bars[bars.length - 1])
      const maxIndex = extraBars.length - 1
      const xCustomScale = scaleLinear().domain([0, maxIndex]).range([0, width])
      let endX = xCustomScale(0)

      const getEndX = (bar: any, index: number) => {
        if (index !== 0 && index !== maxIndex) {
          endX = xScale(bar.data.indexValue) - bar.width / 2
        }

        if (index === maxIndex) {
          endX = xCustomScale(maxIndex)
        }

        return endX
      }

      const newLine = line<Bar>()
      const lineGenerator = newLine
        .curve(curveStepAfter)
        .x(getEndX)
        .y(bar => yScale(bar.data.data.expected || 0))

      return (
        <path
          d={lineGenerator(extraBars) || ''}
          fill="none"
          stroke={lineColor}
          strokeDasharray="1%"
          strokeLinecap="round"
          strokeWidth="2"
          style={{ pointerEvents: 'none' }}
        />
      )
    }
  }
  const expectedLayerSection = () => {
    return generateExpactedLayer(Token.color.accent)
  }
  return (
    <>
      {chartData && yearOptions?.length ? (
        <Group>
          <Box p="s-16">
            <Box>
              <Flex justifyContent="space-between">
                <YearSelectButton options={yearOptions} onChangeCallback={onChange} />
                <TabBar
                  variant="segmented fit"
                  height="s-40"
                  onChange={selected => setNavigationTabValue(selected)}
                  value={navigationTabValue}
                >
                  <TabBar.Item to={Tabs.Goals}>Goals</TabBar.Item>
                  <TabBar.Item to={Tabs.Roadmap}>Roadmap</TabBar.Item>
                </TabBar>
              </Flex>

              <Flex style={{ flexGrow: 1 }} height={280}>
                <BaseChartInner
                  id={1}
                  data={{ progress_history: chartData, targets: [] }}
                  showButtons={false}
                  isNew
                  tooltip={({ value }) => {
                    return <TooltipContainer>{formatPercentage(value)}</TooltipContainer>
                  }}
                  showTargetLegend
                  targetLayerTitle="Target"
                  layers={[expectedLayerSection]}
                  findMaxValue={() => 1}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 8,
                    tickRotation: 0,
                    format: (d: any) => {
                      return formatPercentage(d)
                    },
                    tickValues: [0, 0.2, 0.4, 0.6, 0.8, 1],
                  }}
                />
              </Flex>
            </Box>
          </Box>
        </Group>
      ) : (
        <AnalyticsSkeleton />
      )}
    </>
  )
}
