import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useGetGrowthPlan, useGetGrowthPlanRelation } from '@src/api/growthPlans'

interface Props {
  data?: GrowthPlanInterface
  isLoading: boolean
}

export const useGetEmployeeGrowthPlan = (employeeId: number): Props => {
  const { data: relation, isLoading: isRelationLoading } =
    useGetGrowthPlanRelation(employeeId)
  const { data, isLoading: isGrowthPlanLoading } = useGetGrowthPlan(
    relation?.current_growth_plan?.growth_plan?.id,
  )

  return {
    isLoading: isRelationLoading || isGrowthPlanLoading,
    data,
  }
}
