import { AudienceInterface } from '@src/components/AddAudienceSidebar/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { FieldOptions, IdAndName } from '@src/interfaces/index'
import { FileInterface } from './files'
import { FinalGrade } from './performance'

export enum MeetingStatus {
  Completed = 'completed',
  Scheduled = 'scheduled',
}

export enum EmployeeRelation {
  DirectReport = 'direct report',
  FunctionalReport = 'functional report',
  LineManager = 'line manager',
  FunctionalManager = 'functional manager',
}

export enum MeetingTemplateStatus {
  Draft = 'draft',
  Active = 'active',
}

export enum MeetingTemplateFrequency {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  BI_WEEKLY = 'bi_weekly',
}

export enum TemplateItemRole {
  TalkingPoints = 'talking_points',
  ActionItems = 'action_items',
  PerformanceRating = 'performance_rating',
  PersonalNotes = 'personal_notes',
}

export enum TemplateItemContentType {
  Text = 'text',
  TextList = 'text__list',
  TextCheckbox = 'text__checkbox',
  DropdownSingleChoice = 'dropdown__single_choice',
}

export enum SummaryStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export enum ActionItemsStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export interface MeetingCompanionInterface {
  id: number
  employee: EmployeeOptionInterface
  employee_relation: EmployeeRelation
  next_meeting_date: string | null
  days_since_last_meeting: number | null
  can_setup_meeting: boolean
  latest_meeting_id: number | null
  next_meeting?: NextMeetingInterface
}

export interface NextMeetingInterface {
  object_id: number
  feedback_description: string
  feedback_link: string
  feedback_event_link: string
  feedback_title: string
}

export interface MeetingInterface {
  id: number
  start: string
  end: string
  status: MeetingStatus
  employee: EmployeeOptionInterface
  link: string
}

export interface MeetingWithManagersStats {
  last_lm_meeting_date: string | null
  last_fm_meeting_date: string | null
}

export interface MeetingWithCompanionDetail {
  last_meeting_date: string | null
  avg_days_between_meetings: number | null
  relation: EmployeeRelation
  relation_employee: EmployeeOptionInterface
  request_employee: EmployeeOptionInterface
}

export enum OneToOneRelation {
  LineManager = 'line_manager',
  FunctionalManager = 'functional_manager',
}

export enum OneToOneEmployeeRelation {
  LineManager = 'line_manager',
  FunctionalManager = 'functional_manager',
  Report = 'report',
}

export interface Attendee extends EmployeeOptionInterface {
  department?: IdAndName
}

interface MeetingSeries {
  id: number
  event_id: string
  start: string
  end: string
  recurrence: string
}

export interface ValidatedMeeting {
  meeting?: MeetingEvent
  manager_meeting?: OneToOneMeeting
}

export interface OneToOneMeeting {
  id: number
  manager: Attendee
  employee: Attendee
  relation_type: OneToOneRelation
  employee_relation_type: OneToOneEmployeeRelation
  meeting_series: MeetingSeries | null
  next_meeting_start: string | null
  next_meeting_end: string | null
  next_meeting_id: number | null
  next_meeting_link: string | null
}

export interface OneToOneMeetingsStats {
  total_setup_rate: number
  lm_setup_rate: number
  fm_setup_rate: number
}

export enum MeetingEventStatus {
  Completed = 'Completed',
  Scheduled = 'Scheduled',
}

export enum MeetingNotesOrigin {
  Platform = 'platform',
  Extension = 'browser_extension',
}

export enum MeetingEventCategory {
  OneToOne = 'one_to_one',
}

export enum GoogleCalendarEventStatus {
  ActionRequired = 'needsAction',
  Declined = 'declined',
  Tentative = 'tentative',
  Accepted = 'accepted',
}

interface EventAttendee extends Attendee {
  comment: string
}

export interface GoogleCalendarEventAttendee extends EventAttendee {
  response_status: GoogleCalendarEventStatus
}

export interface MeetingEvent<T extends EventAttendee = EventAttendee> {
  id: number
  title: string
  description: string
  start: string
  end: string
  status: MeetingEventStatus
  category: MeetingEventCategory
  organizer: Attendee
  parent_event_id: string | null
  is_series: boolean
  employee_relation_type: OneToOneEmployeeRelation
  recurrence: string | null
  link: string
  attendees: T[]
  object_id: number
  feedback_title: string
  feedback_description: string
  feedback_link: string | null
  feedback_event_link: string | null
}

export interface ValueName {
  value: string
  name: string
}

export interface TimeSlot {
  start: string
  end: string
}

interface BusySlot {
  busy: TimeSlot[]
}

export interface FreeBusyCalendar {
  kind: string
  timeMin: string
  timeMax: string
  calendars: Record<string, BusySlot>
}

export interface TemplateItemInterface {
  id?: number
  title: string
  is_private: boolean
  is_available_before_start: boolean
  content_type: TemplateItemContentType
  role: TemplateItemRole
  content: string | string[] | ValueName[]
}

export interface MeetingTemplateInterface {
  id: number
  name: string
  description: string
  frequency: MeetingTemplateFrequency
  duration: number
  audiences: AudienceInterface[]
  items: TemplateItemInterface[]
  created_by: EmployeeOptionInterface
  enable_ai: boolean
  status: MeetingTemplateStatus
}

export interface MeetingNotesFeedback {
  id: number
  manager: EmployeeOptionInterface
  report: EmployeeOptionInterface
  meeting: MeetingEvent
  content: ContentItem[]
  enable_ai: boolean
  enable_personal_notes: boolean
  report_personal_notes: string
  manager_personal_notes: string
  summary: string | null
  summary_status: SummaryStatus | null
  action_items_status: ActionItemsStatus
  attachments: FileInterface[]
  recorded_by: EmployeeOptionInterface | null
  origin: string
  language: string
  field_options: FieldOptions
}

export interface CreateOneToOneMeetingNotePayloadInterface {
  manager: { id: number }
  report: { id: number }
  origin: MeetingNotesOrigin
}

export type ContentItem =
  | ActionItemContent
  | PerformanceRatingContent
  | TalkingPointsContent
interface ContentItemBase {
  title: string
  is_private: boolean
  is_available_before_start: boolean
}
export interface ActionItem {
  completed: boolean
  value: string
}
interface ActionItemContent extends ContentItemBase {
  role: 'action_items'
  content_type: 'text__checkbox'
  content: ActionItem[] | null
}

interface PerformanceRatingContent extends ContentItemBase {
  role: 'performance_rating'
  content_type: 'dropdown__single_choice'
  content: {
    value: null | { name: string; value: FinalGrade }
    content: { name: string; value: FinalGrade }[]
  }
}

interface TalkingPointsContent extends ContentItemBase {
  content_type: 'text__list'
  role: 'talking_points'
  content: { content: string }
}

export interface UpdateOneToOneMeetingNotePayloadInterface {
  summary: string
  content: ContentItem[]
  manager_personal_notes: string
  report_personal_notes: string
  recorded_by: { id: number }
  transcript: string
  language: string
}
