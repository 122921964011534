import React, { useRef } from 'react'
import { requisitionsRequests } from '@src/api/requisitions'
import SideBar from '@src/components/SideBar/SideBar'
import { useTable } from '@src/components/Table/hooks'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { Button, ItemSkeleton, Side, VStack } from '@revolut/ui-kit'
import { RequisitionGroup } from '@src/features/JobPostingFlow/Details/RequisitionGroup'
import { RequisitionItem } from '@src/features/JobPostingFlow/Details/RequisitionItem'
import { JobPostingSpecialisationInterface } from '@src/interfaces/jobPosting'
import { Virtuoso } from 'react-virtuoso'
import { ConnectedRequisitionsFilter } from '@src/features/JobPostingFlow/Details/ConnectedRequisitionsFilter'

interface ConnectRequisitionsSidebarProps {
  requisitions: RequisitionInterface[]
  specialisation: JobPostingSpecialisationInterface
  onClose: VoidFunction
  onConnectRequisitions: (newConnectedRequisitions: RequisitionInterface[]) => void
}

export const ConnectRequisitionsSidebar = ({
  requisitions,
  specialisation,
  onClose,
  onConnectRequisitions,
}: ConnectRequisitionsSidebarProps) => {
  const requisitionIds = requisitions.map(({ id }) => id)
  const scrollRef = useRef<HTMLDivElement>(null)
  const table = useTable<RequisitionInterface>(
    requisitionsRequests,
    [
      {
        filters: [
          {
            name: String(specialisation.id),
            id: specialisation.id,
          },
        ],
        columnName: 'specialisation__id',
        nonResettable: true,
      },
    ],
    [
      {
        sortBy: 'specialisation',
        direction: SORT_DIRECTION.ASC,
        nonResettable: true,
      },
    ],
  )
  const tableRequisitionIds = table.data.map(({ id }) => id)
  const allSelected = tableRequisitionIds.every(id => requisitionIds.includes(id))
  const handleSelectAll = () => {
    if (allSelected) {
      onConnectRequisitions([])
    } else {
      onConnectRequisitions(table.data)
    }
  }
  const handleSelectRequisition = (requisition: RequisitionInterface) => {
    if (requisitionIds.includes(requisition.id)) {
      const newSelectedRequisitionIds = requisitions.filter(
        ({ id }) => id !== requisition.id,
      )
      onConnectRequisitions(newSelectedRequisitionIds)
    } else {
      onConnectRequisitions([...requisitions, requisition])
    }
  }
  return (
    <SideBar
      isOpen
      title="Requisitions"
      subtitle={specialisation.name}
      onClose={onClose}
      sideProps={{ scrollRef }}
    >
      <VStack gap="s-16">
        <ConnectedRequisitionsFilter onFilterChange={table.onFilterChange} />
        <RequisitionGroup
          loading={table.loading}
          selected={allSelected}
          onClick={handleSelectAll}
        >
          <Virtuoso
            data={table.data}
            customScrollParent={scrollRef?.current || undefined}
            overscan={150}
            // https://github.com/petyosi/react-virtuoso/issues/341
            initialItemCount={table.data.length - 1}
            endReached={() => {
              if (table.count > table.data.length) {
                table.fetchNextPage()
              }
            }}
            itemContent={(_, requisition) => {
              return (
                <RequisitionItem
                  key={requisition.id}
                  requisition={requisition}
                  selected={requisitionIds.includes(requisition.id)}
                  onClick={() => {
                    handleSelectRequisition(requisition)
                  }}
                />
              )
            }}
          />
          {table.loading && <ItemSkeleton />}
        </RequisitionGroup>
      </VStack>
      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onClose}>Confirm</Button>
      </Side.Actions>
    </SideBar>
  )
}
