import React, { useEffect } from 'react'
import { CycleSummaryData } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import { Avatar, Flex, Icon, Text, Token, VStack } from '@revolut/ui-kit'
import { CollapsableTableWrapper } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/CollapsableTableWrapper'
import { Stats } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { QuarterGoalsTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterGoalsTable'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { QuarterRoadmapsTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterRoadmapTable'
import { KarmaInterface, KarmaStatsInterface } from '@src/interfaces/karma'
import { QuarterKarmaTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterKarmaTable'
import { gradeSettings } from '@src/pages/Forms/DepartmentForm/Performance/utils'

const QuarterSummary = ({
  goalTableRequests,
  goalsSettings,
  roadmapsTableRequests,
  roadmapsSettings,
  karmaTableRequests,
  karmaSettings,
  selectedCycle,
  cycleStats,
}: CycleSummaryData) => {
  const goalsTable = useTable(
    goalTableRequests,
    goalsSettings.initialFilters,
    goalsSettings.initialSort,
    {
      parentIdFilterKey: 'parent_id',
      childrenFetchOmitFilterKeys: ['object_id'],
    },
  )
  const roadmapsTable = useTable<RoadmapInterface, Stats>(
    roadmapsTableRequests,
    roadmapsSettings.initialFilters,
    roadmapsSettings.initialSort,
  )

  const karmaTable = useTable<KarmaInterface, KarmaStatsInterface>(
    karmaTableRequests,
    karmaSettings.initialFilters,
    karmaSettings.initialSort,
  )
  useEffect(() => {
    goalsTable.onFilterChange([
      ...goalsSettings.initialFilters,
      {
        columnName: 'cycle__id',
        filters: [{ id: selectedCycle.review_cycle_ids[0], name: selectedCycle?.name }],
        nonResettable: true,
        disableQueryParam: true,
      },
    ])
    roadmapsTable.onFilterChange([
      ...roadmapsSettings.initialFilters,
      {
        columnName: 'review_cycle__offset',
        filters: [
          { id: selectedCycle.review_cycle_offsets[0], name: selectedCycle?.name },
        ],
        nonResettable: true,
        disableQueryParam: true,
      },
    ])

    karmaTable.onFilterChange([
      ...karmaSettings.initialFilters,
      {
        filters: [{ name: selectedCycle?.name, id: selectedCycle.karma_cycle_ids[0] }],
        columnName: 'review_cycle__offset',
        nonResettable: true,
        disableQueryParam: true,
      },
    ])
  }, [selectedCycle])

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap="s-16">
          <Avatar useIcon="BarChart" />
          <Text variant="emphasis1">Department Performance Summary</Text>
        </Flex>
      </Flex>
      <VStack space="s-16">
        <CollapsableTableWrapper
          headerTitle="Goals"
          icon={<Icon name="Target" color={Token.color.onAccent} size={24} />}
          count={goalsTable.data.length}
          headerStat={cycleStats?.[0].goal_progress.percent}
          headerStatColor={
            cycleStats
              ? gradeSettings[cycleStats[0].goal_progress.grade].color
              : Token.color.foreground
          }
          statPosition="end"
        >
          <QuarterGoalsTable table={goalsTable} />
        </CollapsableTableWrapper>
        <CollapsableTableWrapper
          headerTitle="Roadmap"
          icon={<Icon name="Map" color={Token.color.onAccent} size={24} />}
          count={roadmapsTable.data.length}
          headerStat={cycleStats?.[0].roadmap_progress.percent}
          headerStatColor={
            cycleStats
              ? gradeSettings[cycleStats[0].roadmap_progress.grade].color
              : Token.color.foreground
          }
          statPosition="end"
        >
          <QuarterRoadmapsTable table={roadmapsTable} />
        </CollapsableTableWrapper>
        <CollapsableTableWrapper
          headerTitle="Karma"
          icon={<Icon name="ArrowsSort" color={Token.color.onAccent} size={24} />}
          count={karmaTable.data.length}
          headerStat={cycleStats?.[0].karma_percentage.percent}
          headerStatColor={
            cycleStats
              ? gradeSettings[cycleStats[0].karma_percentage.grade].color
              : Token.color.foreground
          }
          statPosition="end"
        >
          <QuarterKarmaTable table={karmaTable} />
        </CollapsableTableWrapper>
      </VStack>
    </>
  )
}

export default QuarterSummary
