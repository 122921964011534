import React, { PropsWithChildren } from 'react'
import { Checkbox, Item, Widget } from '@revolut/ui-kit'

interface RequisitionGroupProps {
  loading: boolean
  selected: boolean
  onClick: VoidFunction
}

export const RequisitionGroup = ({
  loading,
  selected,
  onClick,
  children,
}: PropsWithChildren<RequisitionGroupProps>) => {
  return (
    <Widget>
      <Item
        use="label"
        aria-disabled={loading}
        onClick={e => {
          e.preventDefault()
          if (!loading) {
            onClick()
          }
        }}
      >
        <Item.Prefix>
          <Checkbox checked={selected} disabled={loading} />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>{selected ? 'Deselect all' : 'Select all'}</Item.Title>
        </Item.Content>
      </Item>
      {children}
    </Widget>
  )
}
