import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { EmployeeInterface } from '@src/interfaces/employees'

export const useIsGrowthPlansEnabled = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  return featureFlags.includes(FeatureFlags.GrowthPlansEnabled)
}

export const useCanViewGrowthPlans = (user: EmployeeInterface) => {
  const isGrowthPlansEnabled = useIsGrowthPlansEnabled()

  return (
    isGrowthPlansEnabled &&
    user.field_options.permissions?.includes(PermissionTypes.CanViewGrowthPlans)
  )
}

export const useCanCreateGrowthPlans = (user: EmployeeInterface) => {
  const isGrowthPlansEnabled = useIsGrowthPlansEnabled()

  return (
    isGrowthPlansEnabled &&
    user.field_options.permissions?.includes(PermissionTypes.CanCreateGrowthPlans)
  )
}
