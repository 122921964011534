import AdjustableTable from '@components/TableV2/AdjustableTable'
import { GoalsInterface } from '@src/interfaces/goals'
import { GoalsInterfaceWithChildren, GoalsStats } from '@src/api/goals'
import { TableNames } from '@src/constants/table'
import { EmptyGoalTable } from '@src/features/Goals/components/EmptyGoalTable'
import { EntityTypes } from '@src/constants/api'
import React, { useMemo } from 'react'
import { useTableReturnType } from '@components/TableV2/hooks'
import {
  goalsApprovalStatusColumn,
  goalsChangeStatusColumn,
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsWeightColumn,
  singleTargetAdditionalColumns,
} from '@src/constants/columns/goals'
import { Statuses } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { groupFiltersIntoURLQuery } from '@src/utils/table'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

interface QuarterGoalsTableProps {
  table: useTableReturnType<GoalsInterfaceWithChildren, GoalsStats, { [p: string]: any }>
}

export const QuarterGoalsTable = ({ table }: QuarterGoalsTableProps) => {
  const { data: performanceSettings } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle

  const getGoalUrl = (goal: GoalsInterface) => {
    const route =
      goal.status.id === Statuses.draft
        ? ROUTES.FORMS.GOAL.EDIT
        : ROUTES.FORMS.GOAL.PREVIEW
    return {
      ...getLocationDescriptor(pathToUrl(route, { id: goal.id })),
      search: new URLSearchParams(groupFiltersIntoURLQuery(table.filterBy)).toString(),
    }
  }
  const goalRow = useMemo(
    () => ({
      cells: [
        {
          ...goalsNameColumn(getGoalUrl),
          width: 300,
        },

        {
          ...goalsWeightColumn,
          width: 92,
        },
        ...(!isMultipleGoalsTargetsEnabled ? singleTargetAdditionalColumns : []),
        { ...goalsProgressColumn, width: 160 },

        {
          ...goalsOwnerColumn,
          width: 150,
        },
        {
          ...goalsChangeStatusColumn(
            (status, goalId) =>
              table.updateRows(
                r => r.id === goalId,
                r => ({ ...r, status }),
              ),
            false,
          ),
          width: 100,
        },
        {
          ...goalsApprovalStatusColumn,
          width: 50,
        },
      ],
    }),
    [],
  )

  return (
    <AdjustableTable<GoalsInterface, GoalsStats>
      name={TableNames.Goals}
      row={goalRow}
      hideCountAndButtonSection
      hideHeader={table.data?.length === 0}
      emptyState={
        <EmptyGoalTable preselectedType={EntityTypes.department} withAction={false} />
      }
      dataType="Goal"
      {...table}
      expandableType="chevron"
      fetchChildren={(parentIndexes, id) => table.fetchChildren(parentIndexes, id, [])}
      useFetchedChildren
    />
  )
}
