import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { Avatar, Item, Spacer, VStack, Widget } from '@revolut/ui-kit'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import Loader from '@components/CommonSC/Loader'
import { PromotionEligibility } from '@src/features/GrowthPlans/GrowthPlan/components/PromotionEligibility/PromomtionEligibility'
import { useGetEmployeeGrowthPlan } from '@src/features/GrowthPlans/hooks/useGetEmployeeGrowthPlan'
import { EmptyGrowthPlan } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/EmptyGrowthPlan'
import { GrowthPlanHeader } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GrowthPlanHeader'
import { ActionItemsWidget } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/ActionItems/ActionItemsWidget'

interface Props {
  employee: EmployeeInterface
}

export const PreviewGrowthPlan = ({ employee }: Props) => {
  const { data: growthPlan, isLoading: isGrowthPlanLoading } = useGetEmployeeGrowthPlan(
    employee.id,
  )

  if (isGrowthPlanLoading) {
    return (
      <Widget>
        <VStack height={300}>
          <Spacer />
          <Loader />
          <Spacer />
        </VStack>
      </Widget>
    )
  }

  if (!growthPlan) {
    return <EmptyGrowthPlan employee={employee} />
  }

  return (
    <VStack space="s-16">
      <Widget p="s-16">
        <GrowthPlanHeader employee={employee} growthPlan={growthPlan} />
      </Widget>
      <TwoColumnsLayout
        left={
          <VStack space="s-16">
            <Item>
              <Item.Avatar>
                <Avatar useIcon="Document" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Description</Item.Title>
                <Item.Description>{growthPlan.description}</Item.Description>
              </Item.Content>
            </Item>
            <PromotionEligibility employee={employee} growthPlan={growthPlan} />
            {growthPlan.actions.length > 0 && (
              <ActionItemsWidget growthPlan={growthPlan} />
            )}
          </VStack>
        }
        right={null}
        leftFlex={1.5}
      />
    </VStack>
  )
}
