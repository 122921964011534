import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useCanCreateGrowthPlans } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { ManagerActions } from '@src/features/GrowthPlans/GrowthPlan/components/GrowthPlanActionBar/ManagerActions'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
}

export const GrowthPlanActionBar = ({ employee, growthPlan }: Props) => {
  const canCreateGrowthPlans = useCanCreateGrowthPlans(employee)

  if (canCreateGrowthPlans) {
    return <ManagerActions employee={employee} growthPlan={growthPlan} />
  }

  // TODO: add approve actions in scope of https://revolut.atlassian.net/browse/REVC-7491
  return null
}
