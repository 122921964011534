import React from 'react'
import { VStack } from '@revolut/ui-kit'
import { GeneralInfoWidget } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/GeneralInfoWidget'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PromotionEligibility } from '@src/features/GrowthPlans/GrowthPlan/components/PromotionEligibility/PromomtionEligibility'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { ActionItemsWidget } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/ActionItems/ActionItemsWidget'

interface Props {
  employee: EmployeeInterface
}

export const EditGrowthPlanFormContent = ({ employee }: Props) => {
  const { values } = useLapeContext<GrowthPlanInterface>()

  return (
    <VStack space="s-16">
      <GeneralInfoWidget />
      <PromotionEligibility employee={employee} growthPlan={values} />
      <ActionItemsWidget />
    </VStack>
  )
}
