import type { ContentItem, MeetingNotesFeedback } from '@src/interfaces/meetings'

export const getContentItemsByRole = <Role extends ContentItem['role']>(
  meetingNotesDetails: MeetingNotesFeedback | undefined,
  role: Role,
): Extract<ContentItem, { role: Role }> | undefined => {
  const contentItems = meetingNotesDetails?.content?.find(item => item.role === role)
  if (!contentItems) {
    return undefined
  }

  return contentItems as Extract<ContentItem, { role: Role }>
}
